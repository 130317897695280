/* Checkout Page Styles */
.checkout-page {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  color: white;
}

.checkout-form {
  background-color: rgba(52, 52, 52, 0.5);
  border-radius: 12px;
  padding: 30px;
}

.checkout-total {
  font-size: 2em;
  margin-bottom: 30px;
  text-align: center;
}

.checkout-page h2 {
  font-size: 3em;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.file-input-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-name {
  color: #ff4444;
  margin-top: 5px;
  font-size: 0.9em;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  margin-top: 30px;
}

.submit-button:hover {
  background-color: #ff2222;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .cart-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .remove-button {
    margin-top: 10px;
  }
  
  .checkout-form {
    padding: 20px;
  }
}

.payment-info {
  background-color: rgba(52, 52, 52, 0.5);
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code {
  margin-bottom: 20px;
}

.qr-image {
  max-width: 300px;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
}

.bank-details {
  width: 100%;
  max-width: 400px;
}

.bank-details h3 {
  text-align: center;
  margin-bottom: 15px;
  color: #ff4444;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.detail-label {
  font-weight: bold;
  color: #ff4444;
}

.detail-value {
  color: white;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .payment-info {
    padding: 20px;
  }
  
  .detail-row {
    flex-direction: column;
  }
  
  .detail-value {
    margin-top: 5px;
  }
}