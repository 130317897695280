.event-card {
  background-color: rgba(52, 52, 52, 0.5);
  border-radius: 8px;
  width: calc(50% - 48px);
  min-width: 300px;
  height: 180px;
  margin: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  color: white;
  position: relative;
  overflow: hidden;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-card.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.event-card.disabled:hover {
  transform: none;
}

.icon-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  color: white;
  padding: 0 20px;
  scale: 2.5;
}

.event-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}

.event-info h3 {
  margin: 0 0 8px 0;
  font-size: 2.7em;
  color: white;
  align-self: center;
}

.event-details {
  display: flex;
  align-items: center;
  gap: 30%;
  color: #cccccc;
}

.team-size, .price {
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.team-size svg, .price svg {
  margin-right: 8px;
  scale: 1.3;
}

.status, .deadline {
  position: absolute;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8em;
}

.status {
  top: 10px;
  right: 10px;
}

.deadline {
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.deadline.passed {
  background-color: #B43839;
}

.status.closed {
  background-color: #B43839;
  color: white;
}

.status.closing {
  background-color: #FFA500;
  color: black;
}

@media screen and (max-width: 768px) {
  .event-card {
    width: 100%;
    min-width: unset;
    height: 150px;
    margin: 10px 0;
  }

  .icon-container {
    font-size: 2em;
    scale: 1.5;
  }

  .event-info h3 {
    font-size: 1.6em;
  }

  .event-details {
    flex-direction: column;
    gap: 8px;
  }

  .team-size, .price {
    font-size: 1.1em;
  }

  .status, .deadline {
    font-size: 0.7em;
  }
}