.event-page {
  max-width: 800px;
  margin: 0 auto;
  color: white;
  position: relative;
  padding-top: 60px;
}

.event-poster-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -80px;
}

.event-poster {
  width: 100%;
  height: auto;
  border-radius: 12px 12px 0px 0px;
  /* box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3); */
  z-index: 1;
}

.event-content {
  background-color: rgba(52, 52, 52, 0.9);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  
}

.event-title {
  text-align: center;
  font-size: 3.4em;
  margin: 0 0 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.event-icon {
  margin-right: 10px;
}

.details-container {
  padding: 30px;
  font-size : 1.5em;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.detail-item {
  flex: 1;
}

.detail-item h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #cccccc;
}

.detail-value {
  display: flex;
  align-items: center;
  font-size: 1.3em;
}

.detail-value svg {
  margin-right: 10px;
}

.detail-section {
  margin-bottom: 25px;
}

.detail-section h3 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #cccccc;
}

.detail-section p {
  line-height: 1.6;
}

.rules-list, .round-rules ul {
  list-style-type: none;
  padding: 0;
}

.rules-list li, .round-rules li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.rules-list li::before, .round-rules li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #ff4444;
}

.round-rules {
  margin-bottom: 20px;
}

.round-rules h4 {
  color: #ff4444;
  margin-bottom: 10px;
}

.prizes-section {
  margin-top: 30px;
}

.prizes-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.prize-card {
  flex: 1;
  max-width: 200px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s;
}

.prize-card:hover {
  transform: translateY(-5px);
}

.winner {
  border: 2px solid gold;
}

.runner-up {
  border: 2px solid silver;
}

/* Add this new style for Title Event prize cards */
.event-page[data-event="Title Event"] .prize-card {
  border: 2px solid gold;
}

.trophy-icon {
  font-size: 2em;
  margin-bottom: 10px;
  color: #ff4444;
}

.prize-card h4 {
  margin-bottom: 10px;
  color: #cccccc;
}

.prize-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}

.prize-amount svg {
  margin-right: 5px;
}

.add-to-cart-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0 0 12px 12px;
}

.add-to-cart-btn:hover {
  background-color: #ff2222;
}

.add-to-cart-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  z-index: 2;
}

.back-button:hover {
  background-color: #e0e0e0;
}

@media screen and (max-width: 768px) {
  .event-page {
    padding: 60px 20px 20px;
  }

  .event-poster {
    width: 100%;
  }

  .event-poster-container {
    margin-bottom: -40px;
  }

  .event-content {
    padding-top: 30px;
  }

  .event-title {
    font-size: 2em;
    text-shadow: none;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    position: relative;
    z-index: 2;
  
  }

  .details-container {
  margin-top: -40px;
  font-size : 1.2em;
  } 

  .detail-row {
    flex-direction: column;
    gap: 20px;
  }

  .prizes-container {
    flex-direction: column;
    align-items: center;
  }

  .prize-card {
    width: 100%;
    max-width: none;
  }

  .back-button {
    top: 10px;
    left: 10px;
    padding: 8px 16px;
    font-size: 14px;
  }
}



.add-to-cart-btn.added {
  background-color: #4CAF50;
  color: white;
}

.add-to-cart-btn.added:hover {
  background-color: #45a049;
}

.background-video {
  width: 100%;
 height: auto;
 /* object-fit: cover; */
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: -1;
}


/* Remove the old back-button styles */
.back-button {
  display: none; /* or you can completely remove this rule */
}

/* Add new styles for the icon back button */
.icon-back-button {
  color: #ff4444;
  font-size: 2.5em;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: absolute;
  left: 20px;
  top: 30%;
  transform: translateY(-50%);
}

.icon-back-button:hover {
  transform: translateY(-50%) scale(1.1);
}

/* Update event-title container to accommodate the back button */
.title-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px; /* Make space for the back button */
  margin-bottom: 30px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .icon-back-button {
    font-size: 2em;
    left: 10px;
    top:25%;
  }
  
  .title-container {
    padding: 0 40px;
  }
}