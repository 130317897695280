.events-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.events-page {
  background-color: black;
  min-height: 100vh;
  padding: 20px;
}

.events-page h1 {
  color: #B43839;
  text-align: center;
  margin-bottom: 30px;

}

@media screen and (max-width: 768px) {
  .events-container {
    padding: 10px;
  }

  .events-page h1 {
    font-size: 2em;
  }
}


