@font-face {
    font-family: 'Dimitri';
    src: url('/public/DIMITRI_.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Maximum Voltage';
    src: url('/public/Maximum Voltage.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');

body {
    background-color: #28282B;
    margin: 0;
    padding: 0;
}

.logo {
  scale : 4;
  margin-top: 15vh;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0; /* Removes any padding above the logo */
    margin-top: 0; 
    width: 100vw;
    margin-left: 0; /* Ensures no margin is pushing the content down */
}

/*===================*/
/* CONTINUOUS BOB
/*===================*/
@-webkit-keyframes bob {
    0% {
      -webkit-transform: translateY(-8px) scale(0.4);
      transform: translateY(-8px) scale(0.4);
    }
    50% {
      -webkit-transform: translateY(-4px) scale(0.4);
      transform: translateY(-4px) scale(0.4);
    }
    100% {
      -webkit-transform: translateY(-8px) scale(0.4);
      transform: translateY(-8px) scale(0.4);
    }
  }
  @keyframes bob {
    0% {
      -webkit-transform: translateY(-8px) scale(0.4);
      transform: translateY(-8px) scale(0.4);
    }
    50% {
      -webkit-transform: translateY(-4px) scale(0.4);
      transform: translateY(-4px) scale(0.4);
    }
    100% {
      -webkit-transform: translateY(-8px) scale(0.4);
      transform: translateY(-8px) scale(0.4);
    }
  }
  
  .logo2 {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0) scale(0.4);
    transform: perspective(1px) translateZ(0) scale(0.4);
    max-width: 100%;
    height: auto;
    -webkit-animation: bob 1.5s ease-in-out infinite;
    animation: bob 1.5s ease-in-out infinite;
    transform-origin: top center;
    margin-bottom: -60%;
    margin-top: 10%;
  }

  /* h1 {
    font-family: 'Dimitri', sans-serif;
    font-weight: 200;
    font-size: 80px;
    position: relative;
    color: #000; 
    text-shadow: 
        -1px -1px 0 #ff1a1a,
         1px -1px 0 #ff1a1a,
        -1px  1px 0 #ff1a1a,
         1px  1px 0 #ff1a1a,
        2px 2px 1px #000;
    background: linear-gradient(135deg, 
        #ff4d4d 10%,
        #ff1a1a 50%,
        #cc0000 90%);
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

h1::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(135deg,
        rgba(255,255,255,0.4) 10%,
        transparent 50%);
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    z-index: 1;
} */

h1 {
    /* color: #F70E14; */
    color : white;
    font-family: 'Dimitri', sans-serif;
    font-weight: 200;
    font-size: 100px;
    /* text-shadow: -2px -2px 0 black, 2px -2px 0 black, -4px 4px 0 black, 8px 8px 0 black; */
  }

/* Styling for the video container */
/* .video {
    position: relative; 
    width: 100%; 
    height: auto;
    align-items: center;
    justify-content: center;
  } */
  
  /* Background video styling */
  .background-video {
    width: 100%;
   height: auto;
   /* object-fit: cover; */
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   z-index: -1;
  }


  
  /* Ensuring other elements inside the .video container appear above the video */
  .logo2, h1 {
    position: relative;
    z-index: 1; /* Ensures elements stay above the video */
  }
  
.container_front {
    display : flex;

}

.cont {
    width : 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.container video{
    width : 100%;
    height : 100%;

}

/* .text-box {
    position: absolute;
    top: 0;
    left:0;
    background: #000;
    height:100%;
    width:100%;
    color:#FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:420px;
    font-weight: 900;
    mix-blend-mode: multiply;
    font-family: 'Dimitri', sans-serif;

    
} */

.panel {
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .section {
    width: 100%;
    height: 100vh;
  }

.countdown{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 100px;
}

.countdown h1 {
    /* color: #F70E14; */
    color : white;
    font-size: 130px;
    /* text-shadow: -2px -2px 0 black, 2px -2px 0 black, -4px 4px 0 black, 8px 8px 0 black; */
    font-family: "Unica One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }


/* Home.css updates */
.cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Existing styles remain the same */

  .text-box {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    height: 100%;
    width: 100%;
    color: #F70E14; /* Fallback color if video doesn't load */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(120px, 20vw, 420px);
    font-weight: 900;
    mix-blend-mode: multiply;
    font-family: 'Maximum Voltage', sans-serif;
    padding: 20px;
    text-align: center;
    line-height: 1;
    padding-bottom: 15vh; /* Add this line to move the text up */
}

.background-video:not([poster]) + .text-box {
  color: #F70E14;
}

/* Larger screens */
@media screen and (min-width: 1200px) {
    .text-box {
        font-size: clamp(200px, 18vw, 420px);
    }
}

/* Medium screens */
@media screen and (max-width: 1199px) and (min-width: 769px) {
    .text-box {
        font-size: clamp(150px, 16vw, 300px);
    }
}

/* Mobile screens */
@media screen and (max-width: 768px) {
    .text-box {
        font-size: clamp(80px, 14vw, 200px);
    }
    
    .countdown h1 {
        font-size: clamp(40px, 8vw, 60px);
    }
}

/* Small mobile screens */
@media screen and (max-width: 480px) {
    .text-box {
        font-size: clamp(60px, 13vw, 150px);
    }
}

/* Adjust for different aspect ratios */
@media screen and (max-height: 600px) {
    .text-box {
        font-size: clamp(60px, 12vw, 200px);
    }
}

/* Home.css (updated styles for scroll-arrow) */
.scroll-arrow {
  position: absolute;
  bottom: 60px; /* Increased from 40px to move it up */
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 30px;
  height: 30px;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  animation: bobbing 2s infinite;
}

.mobile-logo-container {
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.mobile-logo {
  width: 100px;
  height: auto;
  scale : 2.7;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .mobile-logo-container {
    top: 20vh;
  }

  .mobile-logo {
    width: 80px;
  }

  .text-box {
    font-size: clamp(80px, 13vw, 150px);
  }
}

@keyframes bobbing {
  0%, 100% {
    bottom: 60px; /* Adjusted to match the new starting position */
  }
  50% {
    bottom: 70px; /* Adjusted to maintain the same 10px bobbing range */
  }
}

/* Responsive styles for the arrow */
@media screen and (max-width: 768px) {
  .scroll-arrow {
    width: 20px;
    height: 20px;
    bottom: 100px; /* Adjusted for mobile */
  }

  @keyframes bobbing {
    0%, 100% {
      bottom: 100px; /* Adjusted for mobile */
    }
    50% {
      bottom: 110px; /* Adjusted for mobile */
    }
  }
}

/* Existing styles remain the same */

/* Base styles for the info panel and embed-video */
.panel.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

.embed-video-container {
  position: relative;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}

.embed-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-content {
  width: 50%;
  padding: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* Responsive styles for mobile devices */
@media screen and (max-width: 768px) {
  .panel.info {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }

  .embed-video-container {
    width: 100vw; /* Full viewport width */
    height: 40vh;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .embed-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the video covers the container */
  }

  .info-content {
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    min-height: 60vh;
    background-color: rgba(40, 40, 43, 0.9);
    position: relative;
    z-index: 2;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .embed-video-container {
    height: 35vh;
  }

  .info-content {
    padding: 1rem;
  }
}

/* Landscape mode adjustments */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .embed-video-container {
    height: 70vh;
  }

  .info-content {
    min-height: 30vh;
  }
}

/* Gradient overlay for the video container */
.embed-video-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 60%,
    rgba(40, 40, 43, 0.8)
  );
  pointer-events: none;
}

@media screen and (min-width: 769px) {
  .embed-video-container::after {
    background: linear-gradient(
      to right,
      transparent 60%,
      rgba(40, 40, 43, 0.8)
    );
  }
}

/* Add these styles to your existing Home.css */
.register-button {
  position: absolute;
  bottom: 120px;
  left: 50%;
  
  transform: translateX(-50%);
  z-index: 2;
  padding: 15px 30px;
  font-family: 'Dimitri', sans-serif;
  font-size: 20px;
  color: white;
  background-color: #F70E14;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.register-button:hover {
  background-color: #d50912;
  transform: translateX(-50%) scale(1.05);
}

@media screen and (max-width: 768px) {
  .register-button {
    bottom: 140px;
    font-size: 20px;
    padding: 12px 24px;
  }
}