.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: #28282B; */
    background-color: black;
    color: white;
  }
  
  .success-content {
    text-align: center;
    padding: 2rem;
  }
  
  .success-title {
    font-size: 10rem;
    margin-bottom: 1rem;
    font-family: 'Dimitri', sans-serif;
    color: #ff4444;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .success-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #cccccc;
  }
  
  .home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
  }
  
  .home-button:hover {
    background-color: #ff2222;
  }
  
  .home-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .success-title {
      font-size: 3rem;
    }
  
    .success-subtitle {
      font-size: 1.2rem;
    }
  
    .home-button {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
    }
  }