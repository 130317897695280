.cart-page {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .cart-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    display : flex;
    flex-direction: column;
  }
  
  .cart-items {
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    gap : 20px;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
  }
  
  .item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .item-details h3 {
    margin: 0;
    color: #fff;
  }
  
  .item-price {
    color: #ff4444;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .form-status {
    color: #4CAF50;
    margin: 5px 0;
  }
  
  .item-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .action-button.edit {
    background-color: #2196F3;
    color: white;
  }
  
  .action-button.remove {
    background-color: #f44336;
    color: white;
  }
  
  .cart-summary {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .total-price {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    margin-bottom: 15px;
    color: white;
  }
  
  .checkout-button {
    width: 100%;
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1em;
    cursor: pointer;
  }
  
  .checkout-button:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  .warning-message {
    color: #ff4444;
    margin-top: 10px;
    text-align: center;
  }
  
  .empty-cart {
    text-align: center;
    color: white;
  }
  
  .continue-shopping {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }